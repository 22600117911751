import React from 'react';

import {
    useNavigate,
    useParams,
    useSearchParams,
    Link
} from "react-router-dom";


import { AggregatorApi } from '../aggregator-api/api'
import { SearchResults, SearchResult } from '../objects/SearchResults';
import { AggregatorSources, AggregatorSource } from '../objects/AggregatorSources';
import Activity from './components/Activity';
import ActivitySkeleton from './components/ActivitySkeleton';
import Pagination from './components/Pagination';
import SearchForm from './components/SearchForm';
import SearchFormSkeleton from './components/SearchFormSkeleton';
import Filter from './components/Filter';

const aggregatorPrimeCacheEvent = new CustomEvent("AggregatorPrimeCacheEvent");

class Search extends React.Component {

    constructor(props) {
        super(props);
        // Apparently I need to bind certain methods
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.setPerPage = this.setPerPage.bind(this);
        this.search = this.search.bind(this);
        this.filter = this.filter.bind(this);
        this.sort   = this.sort.bind(this);

        this.query_params = this.props.useSearchParams[0];
        this.setQueryParams = this.props.useSearchParams[1];
        this.state = {
            loadingSources: true,
            loadingResults: true,
            countingActivities: true,
            countingSources: true,
            resultsError: false,
        }
        this.sources = [];
        this.results = [];
        // Test data
        this.total_activities_count = 0;
        this.total_organisations = 0;

        // Sanity checking
        this.statii = ["all", "open", "closed", "forthcoming"];
        this.sort_orders = [
            "newest_update",
            "oldest_update",
            "most_recently_opened",
            "closing_soonest",
        ];
        this.default_sort_orders = {
            "all": "newest_update",
            "open": "most_recently_opened",
            "closed": "newest_update",
            "forthcoming": "newest_update",
        };
        this.status_sort_orders = {
            "all": ["newest_update", "oldest_update"],
            "open": ["most_recently_opened", "closing_soonest"],
            "closed": ["newest_update", "oldest_update"],
            "forthcoming": ["newest_update", "oldest_update"],
        }

        let page = parseInt(this.query_params.get("page"));
        if (isNaN(page)) {
            page = 1;
        } else {
            page = page;
        }
        let per_page = parseInt(this.query_params.get("per_page"));
        if (isNaN(per_page)) {
            per_page = 25;
        }
        let status = this.query_params.get("status");
        if (this.statii.indexOf(status) < 0) {
            status = "all";
        }
        let sort = this.query_params.get("sort");
        if (this.sort_orders.indexOf(sort) < 0) {
            sort = "newest_update";
        }
        
        let search = this.query_params.get("search");
        if (search == null) {
            search = "";
        }
        let organisation = this.query_params.get("organisation");
        if (organisation == null) {
            organisation = "";
        }

        // In case we need to diverge for whatever reason
        this.query_data = {
            status: status,
            sort: sort,
            search: search,
            page: page,
            per_page: per_page,
            organisation_id: organisation,
            organisation: null
        };
        this.aggregator_api = new AggregatorApi(process.env.BITIO_BASE_URL, process.env.IS_TEST == "True");
        this.prefetch_pages = false; //process.env.PREFETCH == "True"; // For now we disable the prefetch as we have disabled the api cache, so there's no point calling the endpoint loads of times.
    }

    componentDidMount() {
        this.init();

        // Bind an event or two
        let self = this;
        window.addEventListener("AggregatorPrimeCacheEvent", function() {
            self.handleAggregatorPrimeCacheEvent();
        });
    }

    async init() {
        this.countActivities(null);
        if (this.query_data.organisation_id) {
            // Tells us to load the search results after the organisation data 
            // has been retreived
            this.loadSources(true);
        } else {
            // No point waiting around if we're not searching by organisation anyway
            this.loadResults();
            this.loadSources(false);
        }
    }

    handleAggregatorPrimeCacheEvent() {
        if (!this.prefetch_pages) {
            // Don't necessarily prefetch
            return;
        }
        // Make sure that there's a nice recent set of results for the most obvious 
        // next choices of user interaction / filtering etc
        let search_clone = Object.assign({}, this.query_data);
        // Preload the next page
        search_clone.page = search_clone.page + 1;
        this.aggregator_api.prefetch_query(search_clone);
        // Reset
        search_clone.page = this.query_data.page;
        // Preload all statuses
        let statii = new Set(this.statii);
        statii.delete(this.query_data.status);
        for (let status of statii) {
            search_clone.status = status;
            this.aggregator_api.prefetch_query(search_clone);
            this.aggregator_api.prefetch_query_count(search_clone);
        }
        // Reset
        search_clone.status = this.query_data.status;
        // Preload sort orders because why not
        for (let sort of this.status_sort_orders[search_clone.status]) {
            // don't bother with the current sort as it's just been loaded
            if (sort != this.query_data.sort) {
                search_clone.sort = sort;
                this.aggregator_api.prefetch_query(search_clone);
                this.aggregator_api.prefetch_query_count(search_clone);
            }
        }
        // Reset
        search_clone.sort = this.query_data.sort;
    }

    serialiseSearch() {
        return {
            status: this.query_data.status,
            sort: this.query_data.sort,
            search: this.query_data.search,
            page: this.query_data.page,
            per_page: this.query_data.per_page,
            organisation: this.query_data.organisation ? this.query_data.organisation.url : ""
        }
    }

    async loadSources(loadResultsAfter) {
        this.setState({ loadingSources: true });
        this.setState({ countingSources: true });
        let response = await this.aggregator_api.loadSources();
        if (response) {
            try {
                this.sources = new AggregatorSources(response.data);
                this.total_organisations = response.data.length
                if (loadResultsAfter == true) {
                    // FOR NOW, once we've loaded the sources, we can load the results
                    this.query_data.organisation = this.sources.get(this.query_data.organisation_id);
                    this.loadResults();
                }
            } catch(e) {
                this.setState({ sourceError: true });
                console.error(e);
            }
            this.setState({ countingSources: false });
            this.setState({ loadingSources: false });
        } else {
            this.setState({ sourceError: true });
            this.setState({ countingSources: false });
            this.setState({ loadingSources: false });
            if (loadResultsAfter == true) {
                this.setState({ resultsError: true });
                this.setState({ loadingResults: false });
            }
        }
    }

    async loadResults() {
        // Mark as loading
        this.setState({ loadingResults: true });
        // Unset error state
        this.setState({ resultsError: false });
        // Update the query string to exactly what we searched for
        this.setQueryParams(this.serialiseSearch(), { replace: true});

        let response = await this.aggregator_api.query(this.query_data);
        let count_response = await this.aggregator_api.query_count(this.query_data);
        if (response && count_response) {
            try {
                this.results = new SearchResults(this.query_data, response.data, count_response.data);
            } catch (e) {
                console.error(e);
                this.setState({ resultsError: true });
            }
            this.setState({ loadingResults: false });
        } else {
            this.setState({ resultsError: true });
            this.setState({ loadingResults: false });
        }

        // Fire an event to aggressively collect some other information so that the caches are primed
        window.dispatchEvent(aggregatorPrimeCacheEvent);
    }

    async countActivities() {
        this.setState({ countingActivities: true });
        let response = await this.aggregator_api.totalActivities();
        if (response) {
            this.total_activities_count = response.data[0].toLocaleString();
        }
        this.setState({ countingActivities: false });
    }

    search(e) {
        e.preventDefault();
        this.query_data.organisation = this.sources.get(e.target.source.value);
        this.query_data.search = e.target.search_text.value;
        // Set page back to page 1 thanks
        this.query_data.page = 1;
        this.setQueryParams(this.serialiseSearch(), { replace: true});
        this.loadResults();
        return false;
    }

    filter(e) {
        e.preventDefault();
        this.query_data.status = e.target.dataset.state;
        this.query_data.sort = this.default_sort_orders[this.query_data.status];
        // Set page back to page 1 thanks
        this.query_data.page = 1;
        this.setQueryParams(this.serialiseSearch(), { replace: true});
        this.loadResults();
        return false;
    }

    sort(e) {
        e.preventDefault();
        this.query_data.sort = e.target.value;
        // Set page back to page 1 thanks
        this.query_data.page = 1;
        this.setQueryParams(this.serialiseSearch(), { replace: true});
        this.loadResults();
        return false;
    }

    nextPage(e) {
        e.preventDefault();
        this.query_data.page += 1;
        this.setQueryParams(this.serialiseSearch(), { replace: true});
        this.loadResults();
        return false;
    }

    previousPage(e) {
        e.preventDefault();
        this.query_data.page -= 1;
        this.setQueryParams(this.serialiseSearch(), { replace: true});
        this.loadResults();
        return false;
    }

    setPerPage(e) {
        e.preventDefault();
        this.query_data.per_page = e.target.getAttribute("data-per-page");
        // Set page back to page 1 thanks
        this.query_data.page = 1;
        this.setQueryParams(this.serialiseSearch(), { replace: true});
        this.loadResults();
        return false;
    }

    render() {
        return (
            <>
                <form action="" onSubmit={this.search} method="GET" data-preserve-scrolltop="1" id="agg-search-form">
                    <div className="dss-rhino agg-banner-container">
                        <div className="dss-rhino dss-rhino-padding-bottom-20 agg-banner">
                            <div className="container-fluid">
                                <div className="dss-text-brand-color">
                                    {this.state.loadingResults || this.state.countingActivities || this.state.resultsError || this.state.sourceError ? 
                                        (
                                            <h1>Browse Citizen Space activities</h1>
                                        ) : (
                                            <h1 className="agg-results-loaded-heading">Browse {this.total_activities_count} Citizen Space activities from {this.total_organisations} organisations</h1>
                                        )
                                    }
                                    <p className="lead">Find inspiration for your community engagement, activity and public participation work</p>
                                </div>
                                { !this.state.loadingSources && !this.state.sourceError ? (
                                    <SearchForm query_data={ this.query_data } sources={ this.sources } />
                                ) : <SearchFormSkeleton />          }
                            </div>
                        </div>
                    </div>
                    <Filter filter={ this.filter } sort={ this.sort } query_data={ this.query_data } results={ this.results } />
                </form>
                <div className="dss-rhino dss-rhino-greige dss-rhino-padding-bottom-30">
                    <div className="container-fluid">
                        {this.state.loadingResults ? 
                            (
                                <div className="agg-skeleton agg-skeleton-activities">
                                    <h2 className="agg-results-heading agg-skeleton-results-heading">&nbsp;</h2>
                                    <ul className="list-unstyled" id="activities">
                                        <ActivitySkeleton />
                                        <ActivitySkeleton />
                                        <ActivitySkeleton />
                                        <ActivitySkeleton />
                                    </ul>
                                </div>
                            ) : (
                                <>
                                { this.state.resultsError ? 
                                    (
                                        <div className="dss-rhino text-center agg-searching">
                                            <p className="lead dss-lead-lg">
                                                Could not load results, please try again later.
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            {this.results.validCount() ? 
                                                <h2 className="agg-results-heading" dangerouslySetInnerHTML={{ __html: this.results.resultCountMessage(false) }}></h2>
                                                :
                                                ""
                                            }
                                            <ul className="list-unstyled" id="activities">
                                                {this.results.activities.map((activity, key) => {
                                                    return <Activity key={ "activity-"+key } activity={ activity } query_data={ this.query_data } sources={ this.sources } />
                                                })}
                                            </ul>
                                            <Pagination previousPage={ this.previousPage } nextPage={ this.nextPage } setPerPage={ this.setPerPage } query_data={ this.query_data } results={ this.results } />
                                        </>
                                    )
                                }
                                </>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }

}

function withSearchParams(Component) {
    return props => <Component {...props} useSearchParams={useSearchParams()} />;
}

export default withSearchParams(Search);


